<div class="content">
    <mat-card appearance="outlined">
        <h3 class="panel-header">Verilerinizin Kopyasını Alın</h3>
        <p class="panel-description">
            Verileriniz size aittir ve dilediğiniz zaman arşivi indirebilirsiniz.
        </p>

        <form>
           
            <mat-radio-button class="col-sm-4" value="google">
                Bağlantılar, kişiler, hesap geçmişi ve profilinize ve etkinliğinize göre sizin hakkında çıkardığımız bilgileri de içeren daha büyük veri arşivini indirin.
              </mat-radio-button>
              <br>
              <br>
              <mat-radio-button class="col-sm-4" value="sms"> Spesifik olarak istediğiniz bir şey var mı? En çok ilgilendiğiniz veri dosyalarını seçin. </mat-radio-button>
      
              <br>
              <br>
              <mat-radio-group class="row">
                <mat-radio-button class="col-sm-2" value="sms"> Profil Bilgileri </mat-radio-button>

              </mat-radio-group>
           
        </form>
      </mat-card>
</div>