import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AccountSettingsService } from '@common/auth/account-settings.service';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss', '../panel.scss'],
})
export class AccountSettingsComponent implements OnInit {
  public personalInfoForm = this.fb.group({
    first_name: [this.auth.user.first_name, Validators.required],
    last_name: ['', Validators.required],
    gender: ['', Validators.required],
    country: [''],
    city: [''],
  });

  public accountInfoForm = this.fb.group({
    phone: [''],
    business_email: [this.auth.user.business_mail],
    email: [this.auth.user.email],
  });

  public passwordForm = this.fb.group({
    old_password: ['', Validators.required],
    new_password: ['', Validators.required],
    new_password_confirm: ['', Validators.required],
  });

  public get emails() {
    return this.accountInfoForm.controls['emails'] as FormArray;
  }

  constructor(
    public fb: FormBuilder,
    public auth: AuthService,
    public account: AccountSettingsService,
    private toast: Toast
  ) {}

  ngOnInit(): void {
    this.account.getDetails().subscribe((data) => {
      this.personalInfoForm.patchValue(data.data);
      this.accountInfoForm.patchValue(data.data);
    });
  }

  public getData() {
    return {
      ...this.accountInfoForm.value,
      ...this.personalInfoForm.value,
    };
  }

  public submitPersonalInfo() {
    this.account.putAll(this.getData()).subscribe((data) => {
      this.toast.open('Hesap bilgileriniz başarıyla güncellendi.');
    });
  }

  public submitAccountInfo() {
    this.account.putAll(this.getData()).subscribe((data) => {
      this.toast.open('Hesap bilgileriniz başarıyla güncellendi.');
    });
  }

  public changePassword() {
    this.account.changePassword(this.passwordForm.value).subscribe((data) => {
      if (data.success) {
        this.toast.open('Şifreniz başarıyla güncellendi.');
      } else {
        this.toast.open(data.message);
      }
    });
  }
}
