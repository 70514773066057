import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { User } from 'src/app/models/user';

const BASE = 'account-settings';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {
 
 

  constructor(
    private http:AppHttpClient
  ) { }

  public getDetails() {
    return this.http.get<StandartResponse<User>>(`${BASE}`);
  }

  public putAll(data: any) {
    return this.http.put(`${BASE}/update`, data);
  }

  changePassword(value: Partial<{ old_password: string; new_password: string; new_password_confirm: string; }>) {
    return this.http.put<StandartResponse<any>>(`${BASE}/update-password`, value);
  }
}
