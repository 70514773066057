<div class="content">
  <mat-card appearance="outlined">
    <h3 class="panel-header">Oturumlarınız</h3>
    <p class="panel-description">
      Aşağıda listelenen konumlar, IP adreslerinin ülkenizde, bölgenizde veya
      şehrinizde tahmini olarak nerede yer alabileceğini yansıtır. Aramanın
      doğruluğu, sağlayıcılara ve cihazın konumuna göre değişiklik gösterir. Bu
      tahmin, yalnızca kabataslak bir yol gösterici bilgi olarak
      değerlendirilmelidir.
    </p>
  </mat-card>

  <mat-card appearance="outlined" class="mt-4">
    <h3 class="panel-header">İki Aşamalı Doğrulama</h3>
    <p class="panel-description">
      Tercih edeceğiniz yöntem ile hesabınıza ek güvenlik kazandırın.
      Aktifleştirildikten sonra hesabınıza giriş yapmak istediğinizde SMS veya
      Doğrulama Uygulaması yoluyla gelen şifreyi kullanabileceksiniz. İlk
      aktifleştirildiğinde giriş yaptığınız tüm hesaplardan çıkış yapılır.
    </p>
    <form>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="row"
      >
        <mat-radio-button class="col-sm-4" value="google">
          Google Authenticator
        </mat-radio-button>
        <mat-radio-button class="col-sm-4" value="sms"> SMS Servisi </mat-radio-button>

      </mat-radio-group>
      <button type="submit" class="open-two-factor-button" mat-flat-button color="primary">İki aşamalı doğrulamayı aç</button>
    </form>
  </mat-card>
</div>
