import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Community } from 'src/app/models/community';
import { Company } from 'src/app/models/company';
import { Event } from 'src/app/models/event';
import { JobPosting } from 'src/app/models/job-posting';
import { Post } from 'src/app/models/post';
import { Project } from 'src/app/models/project';
import { Members } from 'src/app/models/relations/members';
import { University } from 'src/app/models/university';
import { CompaniesHomeData } from './companies.service';

const BASE = 'schools';

@Injectable({
  providedIn: 'root',
})
export class SchoolsService {
  constructor(private http: AppHttpClient) {}

  create(value: any) {
    return this.http.post<StandartResponse<University>>(BASE, value);
  }

  public getSchool(id: string) {
    return this.http.get<
      StandartResponse<{
        events: Event[];
        projects: Project[];
        communities: Community[];
        school: University;
        members: Members[];
        jobPostings: JobPosting[];
        posts: Post[];
      }>
    >(`${BASE}/${id}`);
  }
}
