<navbar></navbar>

<div class="container mt-4">
    <h1>Ayarlar</h1>

    <mat-tab-group>
        <mat-tab label="Hesap Seçenekleri"> <account-settings></account-settings> </mat-tab>
        <mat-tab label="Güvenlik"> <security-settings></security-settings> </mat-tab>
        <mat-tab label="İletişim ve Bildirimler"> <notification-settings></notification-settings> </mat-tab>
        <mat-tab label="Veri Gizliliği ve Reklam Tercihleri"> <privacy-settings></privacy-settings> </mat-tab>

      </mat-tab-group>
</div>